<template>
  <v-app>
    <div class="row">

      <!-- Main Content -->
      <div class="col-md-9">
        <Card :title="'Kalender Dokter'">
          <template v-slot:body>
            <!-- Nav -->
            <b-tabs
              class="mt-n3"
              content-class="mt-3"
            >
              <b-tab
                title="Kalender"
                active
              >
                <!-- Calendar -->
                <Calendar />
              </b-tab>
              <b-tab title="Perjanjian">
                <!-- Appointment List -->
                <Table
                  :items="items"
                  :itemExcel="itemExcel"
                  :itemPopUp="itemPopUp"
                  :fields="fields"
                  :fieldPopUp="fieldPopUp"
                  :hasDoctor="true"
                  :perPage="perPage"
                  :currentPage="currentPage"
                  :totalRows="totalRows"
                  :tabFiled="tabFiled"
                  @filter="generalFilter"
                  @btnDeleteOnClick="btnDeleteOnClick"
                  @filterByPatient="generalFilter"
                  @appointmentOnStatusChange="pagination"
                  @pageOnClick="pageOnClick"
                />
              </b-tab>
            </b-tabs>
          </template>
        </Card>
      </div>

      <!-- Doctor Profile Card -->
      <div class="col-md-3">
        <Card title="Dokter">
          <template v-slot:body>
            <div class="d-flex align-items-center">
              <div class="symbol symbol-60 symbol-xxl-100 mr-3 align-self-start align-self-xxl-center">
                <div style='height: 80px; width: 80px; position: relative'>
                  <img
                    class="image"
                    :src="user.photo"
                  >
                </div>
                <i class="symbol-badge bg-success"></i>
              </div>
              <div>
                <a class="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary">{{ user.name }}</a>
                <div class="text-muted">{{ user.polyclinic_name }}</div>
                <div class="mt-2">
                  <button
                    class="btn btn-sm btn-info font-weight-bold mr-2 py-2 px-3 px-xxl-5 my-1"
                    @click="$router.push({ path: '/doctor/detail/' + user.id })"
                  >Info</button>
                </div>
              </div>
            </div>
            <div class="py-9">
              <div class="d-flex mb-2">
                <span class="font-weight-bold mr-2">Email:</span>
                <a class="text-muted text-hover-primary">{{ user.email }}</a>
              </div>
              <div class="d-flex mb-2">
                <span class="font-weight-bold mr-2">Phone:</span>
                <span class="text-muted">{{ user.phone }}</span>
              </div>
            </div>
          </template>
        </Card>
      </div>

    </div>
  </v-app>
</template>

<script>

import Card from '@/view/content/Card.vue'
import Table from '@/component/appointments/Table.vue'
import Calendar from '@/component/doctors/Calendar.vue'
import module from '@/core/modules/CrudModule.js'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

const ApiRoute = 'appointments'

export default {

  components: {
    Card,
    Table,
    Calendar
  },

  data() {
    return {
      // User Session Data
      user: {},
      // filter
      filter: {
        appointment_status_id: '',
        start_date: '',
        end_date: '',
        patient_name: '',
        doctor_id: this.$route.params.id
      },
      // pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      // Table Fields
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "patient_name",
          label: "Pasien",
          sortable: true,
        },
        {
          key: "display_date",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "time",
          label: "Waktu",
          sortable: true,
        },
        {
          key: "action_type_name",
          label: "Jenis Tindakan",
          sortable: true,
        },
        {
          key: "appointment_status_name",
          label: "Status",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      fieldPopUp: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "patient_name",
          label: "Pasien",
          sortable: true,
        },
        {
          key: "display_date",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "time",
          label: "Waktu",
          sortable: true,
        },
        {
          key: "action_type_name",
          label: "Jenis Tindakan",
          sortable: true,
        },
        {
          key: "appointment_status_name",
          label: "Status",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      // Table Items
      items: [],
       itemExcel:[],
      itemPopUp: [],
      // Other
      tabFiled: 0,
    }
  },

  methods: {

    pageOnClick(page) {
      this.currentPage = page
      this.pagination()
    },

    async getUser() {
      this.user = await module.get('doctors/' + this.$route.params.id)
      // If Data Not Found
      if (this.user == null) {
        // Redirect To List
        this.$router.push('/doctors/list')
      }
    },

    async pagination() {
      let filterParams = `&appointment_status_id=${this.filter.appointment_status_id}&doctor_id=${this.filter.doctor_id}&patient_name=${this.filter.patient_name}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`
      let response = await module.paginate(ApiRoute, `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`)
      let pagination = response.meta.pagination
      let responseExcel = await module.get('appointment-excel', `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`)
      let responsePopUp = await module.get('appointment-no-paginations', `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`)
    
      this.itemExcel = responseExcel
      this.itemPopUp = responsePopUp
      this.items = response.data
      this.totalRows = pagination.total
    },

    async generalFilter(sended) {
      this.filter.appointment_status_id = sended.appointment_status_id
      this.filter.patient_name = sended.patient_name
      this.filter.start_date = sended.start_date
      this.filter.end_date = sended.end_date
      this.pagination()
    },

    async btnDeleteOnClick(id) {
      // Delete Data
      let result = await module.delete('appointments/' + id)
      // If Deleted
      if (result) {
        this.$root.$emit('UpdateNewAppointment');
        this.pagination()
      }
    },

  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dokter", route: "" },
      { title: "Daftar Dokter", route: "/doctor/list" },
      { title: "Perjanjian" },
    ])
    this.getUser()
    // Table List
    this.pagination()
  }

}
</script>

<style>
</style>